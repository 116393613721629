import React from "react"
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";
import Layout from "../components/layout";
import ogimage from "../images/ogimages/early-access-program-og.png";
import {componentMap, DataProvider} from "../contentful/data-provider";

export default function OrchestrationPage({data}) {
    const dataProvider = new DataProvider(data);
    const {contentfulPage} = data
    return (
        <Layout>
            <Helmet
                meta={[
                    {
                        property: `og:title`,
                        content: `Agoric - ${contentfulPage?.title ?? 'Early Access Program Invite'}`,
                    },
                    {
                        property: `twitter:title`,
                        content: `Agoric - ${contentfulPage?.title ?? 'Early Access Program Invite'}`,
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        property: `twitter:image`,
                        content: ogimage,
                    },
                    {
                        property: `og:image`,
                        content: ogimage,
                    },
                    {
                        property: `twitter:card`,
                        content: `summary_large_image`,
                    },
                ]}
            >
                <title>Agoric - {contentfulPage?.title ?? 'Early Access Program Invite'}</title>
            </Helmet>
            <div className={'main-page new-main-page'}>
                {dataProvider.getCurrentPageSections().map((section, index) => {
                    return (
                        <>
                            {React.createElement(
                                componentMap[section.type],
                                {content: dataProvider.getElementById(section.id)}
                            )}
                        </>
                    );
                })}
                <section className="process mb-2" id={`apply-today-early-access-page`}>
                    <div className="container text-center pt-4">
                        <div className="row justify-content-center process--header section--header pb-0">
                            <div className="col-lg-12">
                                <h2>Apply to the Early Access Program</h2>
                            </div>
                            <div className="col-10 mt-3">
                                <p>To be accepted into the Early Access Program, demonstrate how your project will
                                    benefit from Agoric Orchestration. We evaluate the compatibility of your project
                                    with our solution, considering both your programming expertise and resource
                                    capabilities.</p>
                            </div>
                            <div className="col-lg-12">
                                <iframe
                                    src="https://cdn.forms-content.sg-form.com/95975a17-19f0-11ef-a372-6edbfb941fae"
                                    frameBorder="0" height={650} className={'w-100'}
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    );
}

export const query = graphql`
    query {
        contentfulPage(contentful_id: {eq: "6YqNeL18vyvOw5UxfyfEIi"}) {
            ... CurrentPageDetails
        }
        allContentfulEntry {
            edges {
                node {
                    ... AllEntryDetails
                }
            }
        }
    }
`;